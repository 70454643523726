<template>
    <div class="p-6 max-w-md mx-auto">
      <h1 class="text-xl font-bold mb-4">Simulasi Perhitungan Deposito</h1>
      
      <div class="shadow rounded p-4 border border-gray-300">
          <form @submit.prevent="calculateInterest" class="space-y-4">
            <div>
              <label class="block text-sm font-medium">Nominal Deposito</label>
              <input type="number" v-model.number="depositAmount" placeholder="0" class="w-full p-2 border rounded border-gray-300" required />
            </div>
            
            <div>
              <label class="block text-sm font-medium">Jangka Waktu (bulan)</label>
              <input type="number" v-model.number="tenure" placeholder="0" class="w-full p-2 border rounded border-gray-300" required />
            </div>
            
            <div>
              <label class="block text-sm font-medium">Suku Bunga per Tahun (%)</label>
              <input type="number" v-model.number="interestRate" placeholder="0" step="0.01" class="w-full p-2 border rounded border-gray-300" required />
            </div>
            
            <div>
              <label class="block text-sm font-medium">Jenis ARO</label>
              <select v-model="aroType" class="w-full p-2 border rounded border-gray-300">
                <option value="none">Tanpa ARO</option>
                <option value="aro_principal">ARO Pokok</option>
                <option value="aro_principal_interest">ARO Pokok + Bunga</option>
              </select>
            </div>
            
            <button type="submit" class="w-full text-white bg-primary hover:bg-secondary duration-300 p-2 rounded">Hitung</button>
          </form>
      </div>
      
      <p class="text-xs text-gray-500 mt-2">*Asumsi 30 hari per bulan</p>
      
      <div v-if="showModal" class="fixed inset-0 flex items-center justify-center bg-b-overlay-half z-[1000]">
        <div class="bg-white p-6 rounded shadow-lg max-w-sm w-full relative">
          <button @click="showModal = false" class="absolute top-2 right-2 bg-gray-200 p-1 rounded">✕</button>
          <h2 class="text-lg font-bold mb-4">Hasil Perhitungan</h2>
          <p>Deposito: {{ formatCurrency(depositAmount) }}</p>
          <p>Jangka Waktu: {{ tenure }} bulan</p>
          <p>Suku Bunga: {{ interestRate }}%</p>
          <p>Bunga Kotor ({{ tenure }} bulan): {{ formatCurrency(grossInterest) }}</p>
          <p>Pajak: {{ formatCurrency(taxAmount) }}</p>
          <p><strong>Bunga Bersih: {{ formatCurrency(netInterest) }}</strong></p>
          <p>Jenis ARO: {{ aroType === 'aro_principal' ? 'ARO Pokok' : aroType === 'aro_principal_interest' ? 'ARO Pokok + Bunga' : 'Tanpa ARO' }}</p>
          <button @click="showModal = false" class="mt-4 w-full bg-red-500 text-white p-2 rounded">Tutup</button>
        </div>
      </div>
    </div>
  </template>
  
  <script setup>
  import { ref } from 'vue';
  
  const depositAmount = ref(0);
  const tenure = ref(0);
  const interestRate = ref(0);
  const aroType = ref("none");
  const showModal = ref(false);
  const grossInterest = ref(0);
  const taxAmount = ref(0);
  const netInterest = ref(0);
  
  const calculateInterest = () => {
    // Reset nilai sebelum perhitungan baru
    grossInterest.value = 0;
    taxAmount.value = 0;
    netInterest.value = 0;
    
    let principal = depositAmount.value;
    const dailyRate = (interestRate.value / 100) / 365;
    
    for (let i = 0; i < tenure.value; i++) {
      let monthlyInterest = principal * dailyRate * 30;
      let tax = principal > 7500000 ? monthlyInterest * 0.2 : 0;
      let netMonthlyInterest = monthlyInterest - tax;
      
      if (aroType.value === "aro_principal_interest") {
        principal += netMonthlyInterest;
      }
      
      grossInterest.value += monthlyInterest;
      taxAmount.value += tax;
    }
    
    netInterest.value = grossInterest.value - taxAmount.value;
    
    showModal.value = true;
  };
  
  const formatCurrency = (value) => {
    return new Intl.NumberFormat('id-ID', { style: 'currency', currency: 'IDR', minimumFractionDigits: 2 }).format(value).replace("Rp", "Rp ");
  };
  </script>
  
  <style scoped>
  </style>
  