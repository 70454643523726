<template>
    <div class="p-6 max-w-md mx-auto">
        <h1 class="text-xl font-bold mb-4">Simulasi Perhitungan Tabungan</h1>
      
        <div class="shadow rounded p-4 border border-gray-300">
            <form @submit.prevent="calculateInterest" class="space-y-4">
            <div>
                <label class="block text-sm font-medium">Saldo Awal</label>
                <input type="number" v-model.number="initialBalance" placeholder="0" class="w-full p-2 border border-gray-300 rounded" required />
            </div>
            
            <div>
                <label class="block text-sm font-medium">Suku Bunga per Tahun (%)</label>
                <input type="number" v-model.number="interestRate" placeholder="0" step="0.01" class="w-full p-2 border border-gray-300 rounded" required />
            </div>
            
            <button type="submit" class="w-full bg text-white bg-primary hover:bg-secondary duration-300 p-2 rounded">Hitung</button>
            </form>
        </div>
      
        <p class="text-xs text-gray-500 mt-2">*Asumsi saldo harian tetap dan jumlah hari per bulan adalah 30</p>
      
        <div v-if="showModal" class="fixed inset-0 flex items-center justify-center bg-b-overlay-half z-[1000]">
            <div class="bg-white p-6 rounded shadow-lg max-w-sm w-full relative">
            <button @click="showModal = false" class="absolute top-2 right-2 bg-gray-200 p-1 rounded">✕</button>
            <h2 class="text-lg font-bold mb-4">Hasil Perhitungan</h2>
            <p>Saldo Awal: {{ formatCurrency(initialBalance) }}</p>
            <p>Jangka Waktu: 30 hari</p>
            <p>Suku Bunga: {{ interestRate }}%</p>
            <p>Bunga Kotor: {{ formatCurrency(grossInterest) }}</p>
            <p>Pajak: {{ formatCurrency(taxAmount) }}</p>
            <p><strong>Bunga Bersih: {{ formatCurrency(netInterest) }}</strong></p>
            <p><strong>Saldo Akhir: {{ formatCurrency(finalBalance) }}</strong></p>
            <button @click="showModal = false" class="mt-4 w-full bg-red-500 text-white p-2 rounded">Tutup</button>
            </div>
        </div>
    </div>
  </template>
  
  <script setup>
  import { ref } from 'vue';
  
  const initialBalance = ref(0);
  const interestRate = ref(0);
  const showModal = ref(false);
  const grossInterest = ref(0);
  const taxAmount = ref(0);
  const netInterest = ref(0);
  const finalBalance = ref(0);
  
  const calculateInterest = () => {
    // Reset nilai sebelum perhitungan baru
    grossInterest.value = 0;
    taxAmount.value = 0;
    netInterest.value = 0;
    finalBalance.value = 0;
    
    const dailyRate = (interestRate.value / 100) / 365;
    grossInterest.value = initialBalance.value * dailyRate * 30;
    taxAmount.value = initialBalance.value > 7500000 ? grossInterest.value * 0.2 : 0;
    netInterest.value = grossInterest.value - taxAmount.value;
    finalBalance.value = initialBalance.value + netInterest.value;
    
    showModal.value = true;
  };
  
  const formatCurrency = (value) => {
    return new Intl.NumberFormat('id-ID', { style: 'currency', currency: 'IDR', minimumFractionDigits: 2 }).format(value).replace("Rp", "Rp ");
  };
  </script>
  
  <style scoped>
  </style>
  